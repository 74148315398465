import React from "react";
// import { graphql } from "gatsby";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";

// import Layout from "../components/Layout";

// export const query = graphql`
//   {
//     sanitySocialMediaPage {
//       pageTitle
//     }
//     allInstaNode(sort: { fields: timestamp, order: DESC }) {
//       posts: edges {
//         post: node {
//           id
//           caption
//           localFile {
//             childImageSharp {
//               id
//               gatsbyImageData(formats: [AUTO, WEBP, AVIF])
//             }
//           }
//         }
//       }
//     }
//   }
// `;

// export default function SocialMedia({
//   data: {
//     allInstaNode: { posts },
//     sanitySocialMediaPage: { pageTitle }
//   }
// }) {
//   return (
//     <Layout title="Social Media" page="social-media" url="/social-media">
//       <h1 className="font-h1">{pageTitle}</h1>
//       <ul className="social-media___image-grid">
//         {posts.map(({ post }, index) => {
//           const image = getImage(post.localFile);

//           return (
//             <li key={post.id}>
//               <a
//                 href={`https://www.instagram.com/p/${post.id}/`}
//                 target="_blank"
//                 rel="noreferrer noopener"
//               >
//                 <GatsbyImage image={image} alt={`Instagram Photo ${index}`} />
//               </a>
//             </li>
//           );
//         })}
//       </ul>
//     </Layout>
//   );
// }

export default function SocialMedia() {
  return <></>;
}
